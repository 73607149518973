import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SEO } from '../components/primitives';
import Booking from '../components/_trial/Booking';
import Feedback from '../components/_trial/Feedback';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Trial = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO
        title="Book a free trial"
        description="SDAA offers a 1-hour free trial for swimmers of all level. 4 y/o to adults."
      />
      <Booking />
      <Feedback />
    </motion.article>
  );
};

export default Trial;

Trial.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

Trial.defaultProps = {
  //
};
