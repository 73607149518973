import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Block, Button as StyledButton } from '../primitives';
import TrialImage from './TrialImage';

const Section = styled.section.attrs({ className: 'relative' })``;

const Calendar = styled.div`
  height: 1000px;
  min-width: 320px;
`;

const ImageFrame = styled.div.attrs({ className: 'absolute' })`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ButtonContainer = styled.div.attrs({})`
  margin: 4rem auto 4rem;
  display: grid;
  grid-gap: 1rem;
  max-width: 200px;
  @media (min-width: 697px) {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem auto 0;
  }
`;

const Button = styled(StyledButton).attrs({ className: 'mx-5' })`
  background: ${props => (props.active ? '#ffd700' : '#2a4365')};
  color: ${props => (props.active ? '#000000' : '#ffffff')};
  :hover {
    color: ${props => (props.active ? '#000000' : '#ffffff')};
  }
`;

const Booking = () => {
  //
  const [visibleCalendar, setVisibleCalendar] = useState('group1');

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }, [visibleCalendar]);

  return (
    <Section>
      <Container>
        <Block>
          <ButtonContainer>
            <Button active={visibleCalendar === 'group1'} type="button" onClick={() => setVisibleCalendar('group1')}>
              4-6 year-old
            </Button>
            <Button active={visibleCalendar === 'group2'} type="button" onClick={() => setVisibleCalendar('group2')}>
              7+ year-old
            </Button>
            <Button active={visibleCalendar === 'group3'} type="button" onClick={() => setVisibleCalendar('group3')}>
              Adults
            </Button>
          </ButtonContainer>
          {visibleCalendar === 'group1' && (
            <Calendar
              className="calendly-inline-widget"
              data-url="https://calendly.com/seadragons/4-6?primary_color=44337A&background_color=f7fafc&text_color=000000"
            />
          )}
          {visibleCalendar === 'group2' && (
            <Calendar
              className="calendly-inline-widget"
              data-url="https://calendly.com/seadragons/7?primary_color=44337A&background_color=f7fafc&text_color=000000"
            />
          )}
          {visibleCalendar === 'group3' && (
            <Calendar
              className="calendly-inline-widget"
              data-url="https://calendly.com/seadragons/adult?primary_color=44337A&background_color=f7fafc&text_color=000000"
            />
          )}
        </Block>
      </Container>
      <ImageFrame>
        <TrialImage />
      </ImageFrame>
    </Section>
  );
};

export default Booking;
