import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import Loadable from 'react-loadable';
import { Container, Loading, Button } from '../primitives';

const Section = styled.section.attrs({ className: 'relative' })``;

const Block = styled.div`
  position: relative;
  padding: 4rem 0 4rem 2rem;
  strong {
    font-size: 1.125rem;
  }
  p {
    margin: 1rem 0 2rem;
  }
`;

const Feedback = () => {
  //
  return (
    <Section>
      <Container>
        <Block>
          <strong>Our booking app not loading?</strong>
          <p>Use our Contact Form instead.</p>
          <Button onClick={() => navigate('/contact#form')}>Contact Form</Button>
        </Block>
      </Container>
    </Section>
  );
};

export default Feedback;

Feedback.propTypes = {
  //
};

Feedback.defaultProps = {
  //
};
